import { stringify } from "query-string";
import { DataProvider } from "ra-core";
import {
  USERS,
  GROUPS,
  EVENT,
  SMARTBIN,
  SMARTBINTYPE,
	SMARTMAT,
  PACKAGE_TYPE,
  CASHIER,
  PAYMENT_PROVIDER,
  PRODUCT,
  EPC_SERIE,
  PACKAGE_RELATION,
  CARD_TX,
  PACKAGES,
} from "../constants/resources";
import { fetchUtils } from "react-admin";
import moment from "moment";
import { object } from "prop-types";

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123

 */

const apiurl = 'https://gw.test.goodless.dev';
const debug = false;

// API Urls
function getApiUrl(resource: any) {
  if (resource === USERS || resource === GROUPS) {
		return apiurl + "/im";
  }
  return apiurl;
}

// Authorization token & headers
const token = localStorage.getItem("token");
const auth_token = "Bearer " + token;
const def_header = new Headers({
  Accept: "application/json",
  Authorization: auth_token,
});
const def_options = {
  headers: def_header,
};

export const formatDate = (dateStr: any) => {
  return moment(dateStr).format("hh:mm - MM/DD/YYYY");
  // const date = new Date(dateStr);
  // return `${date.getUTCHours()}:${date.getUTCMinutes()} - ${date.getMonth()}/${date.getDay()}/${date.getFullYear()}`;
};

const getTotal = (headers: any) => {
  let total = null;
  for (let pair of headers.entries()) {
    if (pair[0] === "content-range") {
      total = pair[1];
      break;
    }
  }
  let idx = total.indexOf("/");
  return +total.slice(idx + 1);
};

const formatResponse = (resource:any, json:any) => {
	var formattedData;

	switch(resource) {
		case CARD_TX:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.idKartenTransaktion,
			}));
			break;
		case PACKAGES:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.EPC,
			}));
			break;
		case USERS:
			formattedData = json.data;
			break;
		case GROUPS:
			formattedData = json.data;
			break;
		// Common resources
		case EVENT:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.eventID,
				paymentMetadata: item.organizerPaymentMetadata,
				paymentProvider: item.organizerPaymentProvider,
			}));
			break;
		case SMARTBIN:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.SID,
			}));
			break;
		case SMARTMAT:
				formattedData = json.map((item: any) => ({
					...item,
					id: item.deviceID,
				}));
				break;	
			case CASHIER: {
			formattedData = json.map((item: any) => ({
				...item,
				id: item.cashierID,
			}));
			break;
		}
		case PACKAGE_TYPE:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.packageTypeID,
			}));
			break;
		case PACKAGE_RELATION:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.packageRelationID,
			}));
			break;	
		case SMARTBINTYPE:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.smartbinTypeId,
			}));
			break;
		case PAYMENT_PROVIDER:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.paymentProviderID,
			}));
			break;
		case PRODUCT:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.productID,
			}));
			break;
		case EPC_SERIE:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.EPCSerieID,
			}));
			break;
		case PACKAGE_RELATION:
			formattedData = json.map((item: any) => ({
				...item,
				id: item.packageRelationID,
			}));
			break;

		default:
			formattedData = json;
			break;
	}
	return formattedData;
}

const addAuthToken = (options:any) => {
	// Add Authorization token
	if (options != null && !options.headers) {
		// @ts-ignore
		options.headers = def_header;
	} else {
		options.headers.set("Authorization", auth_token);
	}
	return options;
}

export default (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  countHeader: string = "Content-Range"
): DataProvider => ({

	getList: (resource, params) => {

		if (debug)
			console.log('getList => ' + resource);

		const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };

		let url = `${getApiUrl(resource)}/${resource}?${stringify(query)}`;

		// Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
		var options = countHeader === "Content-Range" ? { headers: new Headers({ Range: `${resource}=${rangeStart}-${rangeEnd}`, }), } : {};

		//const {json, headers} = await executeRequest(httpClient, url, options);
		options = addAuthToken(options);
		return httpClient(url, options).then(({json, headers}) => {
			const total = getTotal(headers);
			if (!headers.has(countHeader)) {
				throw new Error(
					`The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
				);
			}

			const formattedData = formatResponse(resource, json);

			if (debug)
				console.log({data:formattedData, total});
			return {
				data: formattedData,
				total,
			};
		});
  },

	getOne: (resource, params) => {

		if (debug)
			console.log('getOne => ' + resource + ', params => ' + JSON.stringify(params));

		let url = `${getApiUrl(resource)}/${resource}/${params.id}`;

		var options = {};

		options = addAuthToken(options);
		return httpClient(url, options).then(({json, headers}) => {

			var formattedData;
			if (resource == USERS) {
				if (debug)
					console.log(json);
				formattedData = json;
			} else {
				formattedData = formatResponse(resource, [json]);
			}

			if (Array.isArray(formattedData)) {
				formattedData = formattedData[0];
			}

			if (debug) {
				console.log("resource: " + resource);
				console.log({data:formattedData});
			}
			return {
				data: formattedData,
			};
		});
	},

  getMany: (resource, params) => {

		if (debug)
			console.log('getMany => ' + resource);

		const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

		const url = getApiUrl(resource) + `/${resource}?${stringify(query)}`;

		const options = addAuthToken({});
		return httpClient(url, options).then(({json, headers}) => {

			var formattedData = formatResponse(resource, json);

			if (debug)
				console.log({data:formattedData});
			return {
				data: formattedData,
			};
		});
	},

  getManyReference: (resource, params) => {

		if (debug)
			console.log('getManyReference => ' + resource + ', params=' + JSON.stringify(params));
		if (params.target !== undefined && params.id !== undefined) {
			params.filter[params.target] = params.id;
		}
		//console.log(params);

		const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };

		let url = `${getApiUrl(resource)}/${resource}?${stringify(query)}`;

		// Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
		var options = countHeader === "Content-Range" ? { headers: new Headers({ Range: `${resource}=${rangeStart}-${rangeEnd}`, }), } : {};

		options = addAuthToken(options);
		return httpClient(url, options).then(({json, headers}) => {

			const total = getTotal(headers);
			if (!headers.has(countHeader)) {
				throw new Error(
					`The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
				);
			}
	
			const formattedData = formatResponse(resource, json);

			if (debug)
				console.log({data:formattedData, total});
			return {
				data: formattedData,
				total,
			};
		});
  },

  update: (resource, params) => {

		if (debug)
			console.log('update => ' + resource);

		let url = `${getApiUrl(resource)}/${resource}/${params.id}`;

		var options = {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers: def_header,
    };

		options = addAuthToken(options);
		return httpClient(url, options).then(({json, headers}) => {

			var formattedData;
			if (debug)
				console.log(json);
			if (json.dataValues != undefined) {
				formattedData = formatResponse(resource, [json.dataValues]);
			} else {
				formattedData = formatResponse(resource, [json]);
			}

			if (debug)
				console.log({data: formattedData});
	
			if (Array.isArray(formattedData)) {
				formattedData = formattedData[0];
			}

			return {
				data: formattedData,
			};
		});
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(getApiUrl(resource) + `/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
          headers: def_header,
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {
		if (debug)
			console.log('create => ' + resource + ', params=' + JSON.stringify(params.data));

		let url = `${getApiUrl(resource)}/${resource}`;

		if (resource==CASHIER) {
			if (params.data.eventID == null && (params.data.id != null || params.data.id != ''))
			{
				params.data.eventID = params.data.id;
			}
		}
		else if (resource==EPC_SERIE) {
			delete params.data.EPCSerieID;
		}

		var options = {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: def_header,
    };

		options = addAuthToken(options);
		return httpClient(url, options).then(({json, headers}) => {

			var formattedData;
			if (debug)
				console.log(json);
			if (json.dataValues != undefined) {
				formattedData = formatResponse(resource, [json.dataValues]);
			} else {
				formattedData = formatResponse(resource, [json]);
			}
	
			if (formattedData == undefined) {
				return {data: json};
			}
	
			if (Array.isArray(formattedData)) {
				formattedData = formattedData[0];
			}

			if (debug)
				console.log({data: formattedData});
	
			return {
				data: formattedData,
			};
		});
  },

  delete: (resource, params) =>
    httpClient(getApiUrl(resource) + `/${resource}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
        Authorization: auth_token,
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(getApiUrl(resource) + `/${resource}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "text/plain",
            Authorization: auth_token,
          }),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),
});

const formatCommands = (array: any) => {
  let arr = [];
  let obj = {};

  for (let i = 0; i < array.length; i++) {
    // @ts-ignore
    obj[array[i].string] = array[i].commands;
  }

  arr.push(obj);
  return arr;
};
